'use client';
import { useRouter, useSearchParams, usePathname } from 'next/navigation';
import { useState, useEffect } from 'react';


import Loader from './Loader';

import { logger } from '@logger';
import { loginSocial } from '@utils/api/client';
const UrlGuard = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);

  const linkedinLogin = async () => {
    if (!searchParams) {
      return null;
    }
    const localStoragePath = localStorage && localStorage.getItem('redirectUrl');

    const state = searchParams.get('state') || '';

    if (!state.includes('linkedin')) return null;

    logger.info('[Guard] linkedin execute');

    setLoading(true);

    try {
      const code = searchParams.get('code') || '';

      await loginSocial(code, 'linkedin');
    } catch (e) {
      logger.error(e, '[Guard] Linkedin failed %s', (e as any)?.message);
    } finally {
      setLoading(false);
      router.replace(localStoragePath || pathname || '/');
    }
  };

  const transLogin = async () => {
    if (!searchParams) {
      return null;
    }
    const localStoragePath = localStorage && localStorage.getItem('redirectUrl');
    const state = searchParams.get('state') || '';

    if (!state.includes('onelogin')) return null;

    logger.info('[Guard] transLogin execute');

    setLoading(true);
    try {
      const code = searchParams.get('code') || '';

      await loginSocial(code, 'onelogin');
    } catch (e) {
      logger.error(e, '[Guard] onelogin failed %s', (e as any)?.message);
    } finally {
      setLoading(false);
      router.replace(localStoragePath || pathname || '/');
    }
  };

  useEffect(() => {
    // logger.warn('[GUARD] %o', router.query?.code, router?.query?.state);
    // warning: doesn't work correctly in strict mode
    linkedinLogin();
    transLogin();
  }, [router]);

  return <>{loading && <Loader global />}</>;
};

export default UrlGuard;
