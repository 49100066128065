
'use client';
import { useLocale } from 'next-intl';
import { useEffect } from 'react';

import { pushGAEvent } from './Analytics/Google/GAScript';

import { useUserStore } from '@utils/user/store';

export default function Session() {
  const store = useUserStore();
  const locale = useLocale();

  useEffect(() => {
    if (store.isReady) {
      pushGAEvent(store, 'transinfo_session_updated', locale);
    }
  }, [store.isReady, store.isLoggedIn, locale]);

  useEffect(() => {
    store.fetchUser();
  }, [locale]);

  return <></>;
}