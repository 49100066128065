'use client'
import cn from 'classnames';
import { Inter } from 'next/font/google';

import { useRootBodyScroll } from '@context/BodyScrollContext';

const inter = Inter({
  subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
});


const Body = ({ children }) => {
  const { BodyScrollProvider } = useRootBodyScroll();
  return (

    <body
      className={cn(['p-0', inter.className])}
      cz-shortcut-listen="true"
    >
      <BodyScrollProvider>
        {children}
      </BodyScrollProvider>
    </body>
  );
}

export { Body };