import { ApiUser, StoreUser } from './types';

interface SplitApiUserResult {
  user: StoreUser;
  hasNewsletter: boolean;
  isLoggedIn: boolean;
  isActive: boolean;
  isPremium: boolean;
  accessToken: string;
  refreshToken: string;
  provider: string;
  newsletterData: any;
}

export const splitApiUser = (user: ApiUser): SplitApiUserResult => {
  return {
    user: {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      slug: user.slug,
      avatar: user.avatar,
      email: user.email,
    },
    isLoggedIn: user.isLoggedIn,
    isActive: user.isActive,
    hasNewsletter: user.hasNewsletter,
    newsletterData: user.newsletterData,
    isPremium: user.isPremium,
    accessToken: user.accessToken,
    refreshToken: user.refreshToken,
    provider: user.provider,
  };
};
