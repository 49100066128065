'use client';
import get from 'lodash/get';
import dynamic from 'next/dynamic';
import { useState, useEffect, useContext, createContext } from 'react';

import { useRouter } from '@lib/navigation';

const Popups = {
  changePass: {
    component: dynamic(() => import('./ChangePassword')),
  },
  newsletterSubscribeSuccess: {
    component: dynamic(() => import('./NewsletterSubscribeSuccess')),
  },
  magazineRules: {
    component: dynamic(() => import('./MagazineRules')),
  },
};

type PopupsNames = keyof typeof Popups;

const PopupContext = createContext({
  isOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  close: (_?: boolean) => { },
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  open: (_name: PopupsNames, _params?: any) => { },
});

const ClientPopupProvider = ({ children }: any) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [parameters, setParameters] = useState<any>(null);
  const [popupName, setPopupName] = useState<string | null>(null);

  const router = useRouter();

  const close = (shouldRemoveHash = true) => {
    setOpen(false);
    setParameters(null);
    setPopupName(null);
    if (shouldRemoveHash) {
      removeHash();
    }
  };
  const open = (name: string, params: any) => {
    setParameters({ ...params });
    setOpen(true);
    setPopupName(name);
  };

  const getPopup = (name: string | null, parameters: any) => {
    if (typeof window === 'undefined') {
      return null;
    }

    const popupString: string = get(window.location.href.split('#'), '[1]', '');
    const popupName: string | null = get(popupString.split('?'), '[0]', null);
    const popupExist: boolean = Object.keys(Popups).includes(popupName ?? '');

    if (name && !popupExist) {
      const Component = Popups[name].component;
      return <Component {...parameters} />;
    }
    const searchParams = new URLSearchParams(get(popupString.split('?'), '[1]', ''));
    const params = Object.fromEntries(searchParams.entries());

    if (!popupExist) return null;
    const Component = Popups[popupName ?? ''].component;
    return <Component {...params} />;
  };

  const removeHash = () => {
    if (typeof window === 'undefined') return;
    const removed = get(window.location.href.split('#'), '[0]', null);
    if (!window.location.href.includes('#') || removed === null) return;

    router.push(removed);
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const popupName = get(window.location.href.split('#'), '[1]', null);
    if (popupName === null) return;
    setOpen(true);
  }, []);

  return (
    <PopupContext.Provider value={{ isOpen, close, open }}>
      {children}
      {isOpen && getPopup(popupName, parameters)}
    </PopupContext.Provider>
  );
};
export default ClientPopupProvider;

export const usePopup = () => {
  return useContext(PopupContext);
};
