import { ZodTooBigIssue, ZodTooSmallIssue, z } from 'zod';

const getBorderValueFromIssue = (issue: ZodTooBigIssue | ZodTooSmallIssue) => {
  if (issue.code === 'too_small') {
    if (issue.inclusive && typeof issue.minimum === 'number') {
      return issue.minimum - 1;
    }

    return issue.minimum;
  }

  if (issue.inclusive && typeof issue.maximum === 'number') {
    return issue.maximum + 1;
  }

  return issue.maximum;
};

export interface ErrorTranslations {
  invalidEnumValue: string;
  invalidEmail: string;
  invalidLink: string;
  nonempty: string;
  fieldTooShort: string;
  fieldTooLong: string;
  valueTooLow: string;
  valueTooHigh: string;
  required: string;
  other: string;
}

export const createZodErrorMap = async (translations: ErrorTranslations) => {
  const errorMap: z.ZodErrorMap = (issue) => {
    switch (issue.code) {
      case 'invalid_enum_value':
        return {
          message: translations.invalidEnumValue.replace(
            '[expected]',
            issue.options.join(', '),
          ),
        };
      case 'invalid_string':
        switch (issue.validation) {
          case 'email':
            return {
              message: translations.invalidEmail,
            };
          case 'url':
            return {
              message: translations.invalidLink,
            };
        }

        return {
          message: translations.other,
        };
      case 'too_small':
        switch (issue.type) {
          case 'string':
            if (issue.minimum === 1) {
              return {
                message: translations.nonempty,
              };
            }
            return {
              message: translations.fieldTooShort.replace(
                '[value]',
                getBorderValueFromIssue(issue).toString(),
              ),
            };
          case 'number':
            return {
              message: translations.valueTooLow.replace(
                '[value]',
                getBorderValueFromIssue(issue).toString(),
              ),
            };
        }

        return {
          message: translations.other,
        };
      case 'too_big':
        switch (issue.type) {
          case 'string':
            return {
              message: translations.fieldTooLong.replace(
                '[value]',
                getBorderValueFromIssue(issue).toString(),
              ),
            };
          case 'number':
            return {
              message: translations.valueTooHigh.replace(
                '[value]',
                getBorderValueFromIssue(issue).toString(),
              ),
            };
        }

        return {
          message: translations.other,
        };
      case 'invalid_literal':
        switch (issue.expected) {
          case true:
            return {
              message: translations.required,
            };
        }
    }

    return {
      message: translations.other,
    };
  };

  z.setErrorMap(errorMap);
};
