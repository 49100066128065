'use client'

import { GoogleTagManager } from '@next/third-parties/google'
import Script from 'next/script';
import { useEffect, useState } from 'react';

import { GTM_GLOBAL } from 'enums/gtm';

const enableAnalytics = process.env.NEXT_PUBLIC_ANALYTICS === 'true' ? true : false;

const headScripts = {
  googleSiteVerification: (
    <meta
      name="google-site-verification"
      content="19nDqMsggDPQNfjLbk1RTqyxAHeiGN9DkODy5al6vQY"
    />
  ),
  consentManagerCMP: (
    /* eslint-disable */
    <Script
      type="text/javascript"
      data-cmp-ab="1"
      src="https://cdn.consentmanager.net/delivery/autoblocking/a248964f3beb.js"
      data-cmp-host="b.delivery.consentmanager.net"
      data-cmp-cdn="cdn.consentmanager.net"
      data-cmp-codesrc="1"
    />
    /* eslint-enable */
  ),
  defaultConfig: () => {
    return <script
      dangerouslySetInnerHTML={{
        __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
      
        // Set default consent to 'denied' as a placeholder
        // Determine actual values based on your own requirements
        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'ad_user_data': 'denied',
          'ad_personalization': 'denied',
          'analytics_storage': 'denied'
        });
        // set container default parameters
        gtag('js', new Date());
      `}
      } />
  },
  googleTagManager: (
    <GoogleTagManager gtmId={GTM_GLOBAL} />
  ),
  googleTagManagerNoScript: (
    <noscript>
      <iframe
        style={{ display: 'none', visibility: 'hidden' }}
        src="https://www.googletagmanager.com/ns.html?id=GTM-KXXMPR"
        height="0"
        width="0"
      ></iframe>
    </noscript>
  ),
};

const DefaultHeadScripts = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (window) {
      setIsLoaded(true);
    }
  }, [typeof window]);

  return <>
    {headScripts.defaultConfig()}
    {enableAnalytics && headScripts.googleTagManager}
    {enableAnalytics && headScripts.googleTagManagerNoScript}
    {headScripts.googleSiteVerification}
    {isLoaded && (
      <>
        {headScripts.consentManagerCMP}
      </>
    )}
  </>
};

export { headScripts, DefaultHeadScripts };

