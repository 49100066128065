'use client';
import {
  ToastContainer,
  ToastContainerProps,
  Zoom,
  toast,
} from 'react-toastify';

const settings: ToastContainerProps = {
  autoClose: 8000,
  position: 'top-right',
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  transition: Zoom,
  pauseOnFocusLoss: true,
  role: 'alert',
};

export default function NotificationProvider() {
  return <ToastContainer {...settings} key="react-notify" />;
}

function darkmodeToast(message: string, options: any) {
  const darkmode = document.body.classList.contains('darkmode');

  return toast(message, {
    ...options,
    theme: darkmode ? 'dark' : 'light',
  });
}

export function toastSuccess(message: string) {
  return darkmodeToast(message, { type: 'success', role: 'success' });
}

export function toastError(message: string) {
  return darkmodeToast(message, { type: 'error', role: 'error' });
}
