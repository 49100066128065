import { Language } from 'types/lang';

export const GTM_GLOBAL = 'GTM-KXXMPR';

export const GOOGLE_ANAYLTICS_GLOBAL = 'G-GMY494ND5S';

export const GOOGLE_ANALYTICS = new Map<Language, string>([
  ['pl', 'G-ZR1DM3ZV30'],
  ['en', 'G-Z2EQCW5SBF'],
  ['de', 'G-94RXQ18G47'],
  ['hu', 'G-6K1RNHV4SV'],
  ['lt', 'G-2BDLKZBCNC'],
  ['ro', 'G-0BJDF7Q1G8'],
  ['ua', 'G-EQFS27L898'],
  ['ru', 'G-XCRXC95HGL'],
]);
