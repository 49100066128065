import { useCallback, useEffect, useMemo, useState } from 'react';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const BREAKPOINTS: Record<Breakpoint, number> = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const useBreakpoints = () => {
  const [width, setWidth] = useState(Infinity);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', () => setWidth(window.innerWidth));
  });

  const isBreakpointUp = useCallback(
    (breakpoint: Breakpoint) => {
      return width >= BREAKPOINTS[breakpoint];
    },
    [width],
  );

  const isBreakpointDown = useCallback(
    (breakpoint: Breakpoint) => {
      return width < BREAKPOINTS[breakpoint];
    },
    [width],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isMobile = useMemo(() => isBreakpointDown('md'), [width]);

  const isTablet = useMemo(
    () => isBreakpointUp('md') && isBreakpointDown('lg'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width],
  );

  const isLaptop = useMemo(
    () => isBreakpointUp('lg') && isBreakpointDown('xl'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isDesktop = useMemo(
    () => isBreakpointUp('xl') && isBreakpointDown('xxl'),
    [width],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isWideScreen = useMemo(() => isBreakpointUp('xxl'), [width]);

  return {
    isBreakpointUp,
    isBreakpointDown,
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    isWideScreen,
  };
};
