'use client';

import { useEffect } from 'react';

import {
  ErrorTranslations,
  createZodErrorMap,
} from '@utils/api/validation/messages';

interface ZodTranslationsClientProps {
  translations: ErrorTranslations;
}

export default function ZodTranslationsClient({
  translations,
}: ZodTranslationsClientProps) {
  useEffect(() => {
    createZodErrorMap(translations);
  }, []);

  return null;
}
