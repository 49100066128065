export const LOCALES = ['en', 'pl', 'de', 'hu', 'lt', 'ro', 'ru', 'ua'] as const;
export const NAMESPACES = [
  'auth',
  'authors',
  'category',
  'contact',
  'errors',
  'event',
  'front',
  'magazine',
  'main',
  'menu',
  'newsletter',
  'plural',
  'policy',
  'popup',
  'premium',
  'rules',
  'seo',
  'webinars',
  'profile',
];

export const PAGES = {
  '*': ['main', 'menu', 'errors', 'newsletter', 'plural', 'event', 'popup', 'premium'],
  '/[lang]/*': ['main', 'menu', 'errors', 'newsletter', 'plural', 'seo', 'event'],
  '/[lang]/register': ['auth'],
  '/[lang]/contact': ['contact', 'authors'],
  '/[lang]/contact/[id]': ['contact'],
  '/[lang]/category/video': ['category'],
  '/[lang]/category/podcast': ['category'],
  '/[lang]/category/[id]': ['category'],
  '/[lang]/policy/[[...date]]': ['policy'],
  '/[lang]/policy/new': ['policy'],
  '/[lang]/rules/[[...date]]': ['rules'],
  '/[lang]/rules/black-week-2023': ['rules'],
  '/[lang]/rules/new': ['rules'],
  '/[lang]/rules/agb': ['rules'],
  '/[lang]/rules/regulamin': ['rules'],
  '/[lang]/authors': ['authors'],
  '/[lang]/user/[id]': ['authors'],
  '/[lang]/page/magazin': ['magazine'],
  '/[lang]/page/e-magazyn': ['magazine', 'popup'],
  '/[lang]/page/webinary': ['webinars'],
  '/[lang]/page/iaa': ['event'],
  '/[lang]/premium/offer': ['premium'],
  '/[lang]/premium/checkout/billing-information': ['premium'],
  '/[lang]/premium/checkout/package': ['premium'],
  '/[lang]/premium/checkout/summary': ['premium'],
  '/[lang]/premium/checkout/status': ['premium'],
  '/[lang]/premium/thank-you': ['premium'],
  '/[lang]/profile/edit': ['profile'],
  '/[lang]/profile/notifications': ['profile'],
  '/[lang]/profile/premium': ['profile'],
};
