import(/* webpackMode: "eager" */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/app/src/app/components/AdPopup/AdPopup.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Form/Zod/ZodTranslationsClient.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/Body/Body.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Layout/DarkMode/DarkModeLoader.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/root/NotificationProxy.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/root/Popups/ClientPopupProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/root/SpeechSynthesisProvider.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/root/UrlGuard.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/components/Session.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/context/FeatureContext.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/lib/root/scripts.tsx");
import(/* webpackMode: "eager" */ "/app/src/app/styles/global.scss")