// context for body scroll

import { createContext, useContext } from 'react';

export type BodyScrollContextType = {
  isDefined: true;
  disableScroll: () => void;
  enableScroll: () => void;
};
export const BodyScrollContext = createContext<BodyScrollContextType | any>({});


export const useRootBodyScroll = () => {
  const disableScroll = () => {
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
  };
  const enableScroll = () => {
    document.body.style.position = '';
    document.body.style.width = '';
  };
  const BodyScrollProvider = ({ children }) => {
    return <BodyScrollContext.Provider value={{
      isDefined: true,
      disableScroll,
      enableScroll,
    } as BodyScrollContextType}>
      {children}
    </BodyScrollContext.Provider>;
  };
  return { BodyScrollProvider };
}



export const useBodyScroll = () => {
  const context = useContext(BodyScrollContext);
  if (!context?.isDefined) {
    throw new Error('useBodyScroll must be used within a BodyScrollProvider');
  }
  return context;
};

