'use client'

import { useLocale } from 'next-intl';
import { useEffect } from 'react';

import { logger } from '@logger';
import { UserStore, useUserStore } from '@utils/user';
import { GOOGLE_ANALYTICS, GOOGLE_ANAYLTICS_GLOBAL } from 'enums/gtm';
import { Language } from 'types/lang';

declare let gtag: any;

const enableAnalytics = process.env.NEXT_PUBLIC_ANALYTICS === 'true' ? true : false;

interface GAProps {
  event: string;
  id?: string | number;
  values: any;
}

export interface AnalyticsParams {
  [key: string]: string;
}

export function pushSimpleGAEvent(event: string, values: any = {}) {
  if (!enableAnalytics) {
    logger.debug('Analytics is disabled, to enable set environment variable NEXT_PUBLIC_ANALYTICS=true')
    return;
  }
  if (typeof gtag === 'undefined') {
    logger.error('gtag is undefined')
    return;
  }
  gtag(`event`, event, { ...values, send_to: GOOGLE_ANAYLTICS_GLOBAL });
}

export function pushGAEvent(userStore: UserStore, event: string, locale: string, values: any = {}) {
  if (!enableAnalytics) {
    logger.debug('Analytics is disabled, to enable set environment variable NEXT_PUBLIC_ANALYTICS=true')
    return;
  }
  if (typeof gtag === 'undefined') {
    logger.error('gtag is undefined')
    return;
  }

  const gaLocale = GOOGLE_ANALYTICS.get(locale as Language);

  const valuesWithUserInfo = {
    ...values,
    locale,
    ...userStore.getUserInfoForAnalytics()
  }

  gtag(`event`, event, { ...valuesWithUserInfo, send_to: GOOGLE_ANAYLTICS_GLOBAL });
  gtag(`event`, event, { ...valuesWithUserInfo, send_to: gaLocale });
}

export default function GAScript({ event, values }: GAProps) {
  const userStore = useUserStore();
  const locale = useLocale();

  useEffect(() => {
    if (userStore.isReady) {
      pushGAEvent(userStore, event, locale, values);
    }
  }, [userStore.isReady]);

  return (
    <>
    </>
  );
}
