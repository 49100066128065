export const isDarkMode = () => {
  if (typeof localStorage === 'undefined') return false;

  return localStorage.getItem('darkMode') === 'true';
};

export const setDarkMode = (value: boolean) => {
  if (typeof localStorage === 'undefined') return;

  localStorage.setItem('darkMode', value ? 'true' : 'false');

  if (value) {
    document.body.classList.add('darkmode');
  } else {
    document.body.classList.remove('darkmode');
  }
};

export const toggleDarkMode = () => {
  setDarkMode(!isDarkMode());
};
