import { sendGTMEvent } from '@next/third-parties/google';
import { create } from 'zustand';

import { splitApiUser } from './api';
import { ApiUser, StoreUser } from './types';

import { fetchApi } from '@utils/api/client';

export interface UserStore {
  isReady: boolean;
  isActive: boolean;
  isFetching: boolean;
  user: StoreUser | null;
  isLoggedIn: boolean;
  newsletterData: any;
  isPremium: boolean;
  hasNewsletter: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  provider: string | null;
  isInfoBarVisible: boolean;
  reset: () => void;
  setPremiumOverride: (isPremiumOverride: boolean) => void;
  setIsInfoBarVisible: (isVisible: boolean) => void;
  disablePremiumOverride: () => void;
  getUserInfoForAnalytics: () => UserInfo;
  fetchUser: (force?: boolean) => Promise<void>;
}

export interface UserInfo {
  transinfo_is_logged_in: boolean;
  transinfo_is_newsletter_user: boolean;
  transinfo_user_email: string;
  transinfo_user_provider: string;
}

let controller: AbortController | null = null;

const premiumOverride = {
  isOverridden: false,
  originalIsPremium: false,
};

export const useUserStore = create<UserStore>()((set, get) => {
  const state = {
    isReady: false,
    isActive: false,
    isFetching: false,
    user: null,
    isLoggedIn: false,
    hasNewsletter: false,
    newsletterData: undefined,
    isPremium: false,
    accessToken: null,
    refreshToken: null,
    provider: null,
    isInfoBarVisible: false,
    reset() {
      set({
        user: null,
        isLoggedIn: false,
        hasNewsletter: false,
        newsletterData: undefined,
        isPremium: false,
        accessToken: null,
        refreshToken: null,
        provider: null,
        isReady: true,
        isFetching: false,
      });
    },
    setIsInfoBarVisible(isVisible: boolean) {
      set({ isInfoBarVisible: isVisible });
    },
    setPremiumOverride(isPremiumOverride: boolean) {
      if (!premiumOverride.isOverridden) {
        premiumOverride.originalIsPremium = get().isPremium;
      }
      premiumOverride.isOverridden = true;
      set({ isPremium: isPremiumOverride });
    },
    disablePremiumOverride() {
      if (premiumOverride.isOverridden) {
        set({ isPremium: premiumOverride.originalIsPremium });
      }
      premiumOverride.isOverridden = false;
    },
    getUserInfoForAnalytics() {
      const data = get();
      return {
        transinfo_is_logged_in: data.isLoggedIn,
        transinfo_is_newsletter_user: data.hasNewsletter,
        transinfo_user_email: data.user && data.user.email ? data.user?.email : '',
        transinfo_user_provider: data.provider ? data.provider : '',
      };
    },
    async fetchUser() {
      set({ isFetching: true });

      if (controller) {
        controller.abort();
      }
      let aborted = false;

      controller = new AbortController();
      controller.signal.addEventListener('abort', () => {
        aborted = true;
      });

      try {
        const response = await fetchApi<ApiUser>('/session', {
          signal: controller.signal,
        });

        if (response.status !== 200) {
          get().reset();
          return;
        }

        const data = splitApiUser(response.data);

        if (!data.isLoggedIn) {
          get().reset();
          return;
        }

        if (premiumOverride.isOverridden) {
          data.isPremium = get().isPremium;
        }

        set({
          ...data,
          isReady: true,
          isFetching: false,
        });
      } catch (e) {
        // @ts-ignore
        if (e?.name === 'AbortError' || aborted) {
          return;
        }
        get().reset();
      }
    },
  } as UserStore;

  // TODO: Stupid workaround to make sure the empty store is ready before initializing it. Think of something better.
  setTimeout(() => {
    // should be a one-time thing
    if (typeof window !== 'undefined') {
      window.addEventListener('login', () => {
        get().fetchUser();
        sendGTMEvent({
          event: 'login',
        });
      });

      window.addEventListener('logout', () => {
        get().fetchUser();
      });

      setInterval(() => {
        get().fetchUser();
      }, 15000);
    }
  }, 1);

  return state;
});
