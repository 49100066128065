import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import { ReactNode } from 'react';

import { LOCALES } from 'locales/config';
export const { Link, redirect, usePathname, useRouter } =
  createSharedPathnamesNavigation({
    locales: LOCALES,
    localePrefix: 'as-needed',
  });

export interface BaseSearchParams {
  [key: string]: string | string[] | undefined;
}

export interface LocalizedRouteParams {
  locale: (typeof LOCALES)[number];
}

export interface LocalizedRouteProps<
  Params extends LocalizedRouteParams = LocalizedRouteParams,
  SearchParams extends BaseSearchParams = BaseSearchParams,
> {
  params: Params;
  searchParams: SearchParams;
}

export interface LocalizedLayoutProps<
  Params extends LocalizedRouteParams = LocalizedRouteParams,
> {
  params: Params;
  children: ReactNode;
}
