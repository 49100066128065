'use client';

import { useEffect } from 'react';

import { isDarkMode, setDarkMode } from '@lib/root/darkmode';

export default function DarkModeLoader() {
  useEffect(() => {
    setDarkMode(isDarkMode());
  }, []);

  return null;
}
